.progressBar {
  height: 2px;
  width: calc(100% - 16px);
  animation: progress-bar-animation linear forwards;
}

.progressBar:lang(ar) {
  height: 2px;
  width: calc(100% - 16px);
  animation: progress-bar-animation-arabic linear forwards;
}

@keyframes progress-bar-animation {
  from {
    transform: scaleX(0);
    transform-origin: left;
  }
  to {
    transform: scaleX(1);
    transform-origin: left;
  }
}

@keyframes progress-bar-animation-arabic {
  from {
    transform: scaleX(0);
    transform-origin: right;
  }
  to {
    transform: scaleX(1);
    transform-origin: right;
  }
}
