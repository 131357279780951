.icon {
  svg {
    fill: currentcolor;
  }
}

// icon sizes
.xxxs {
  height: var(--measure-dimension-core-size-10);
  width: var(--measure-dimension-core-size-10);
  min-height: var(--measure-dimension-core-size-10);
  min-width: var(--measure-dimension-core-size-10);
}

.xxs {
  height: var(--measure-dimension-core-size-12);
  width: var(--measure-dimension-core-size-12);
  min-height: var(--measure-dimension-core-size-12);
  min-width: var(--measure-dimension-core-size-12);
}

.xs {
  height: var(--measure-dimension-core-size-14);
  width: var(--measure-dimension-core-size-14);
  min-height: var(--measure-dimension-core-size-14);
  min-width: var(--measure-dimension-core-size-14);
}

.sm {
  height: var(--measure-dimension-core-size-16);
  width: var(--measure-dimension-core-size-16);
  min-height: var(--measure-dimension-core-size-16);
  min-width: var(--measure-dimension-core-size-16);
}

.md {
  height: var(--measure-dimension-core-size-20);
  width: var(--measure-dimension-core-size-20);
  min-height: var(--measure-dimension-core-size-20);
  min-width: var(--measure-dimension-core-size-20);
}

.ml {
  height: var(--measure-dimension-core-size-24);
  width: var(--measure-dimension-core-size-24);
  min-height: var(--measure-dimension-core-size-24);
  min-width: var(--measure-dimension-core-size-24);
}

.lg {
  height: var(--measure-dimension-core-size-26);
  width: var(--measure-dimension-core-size-26);
  min-height: var(--measure-dimension-core-size-26);
  min-width: var(--measure-dimension-core-size-26);
}

.xl {
  height: var(--measure-dimension-core-size-28);
  width: var(--measure-dimension-core-size-28);
  min-height: var(--measure-dimension-core-size-28);
  min-width: var(--measure-dimension-core-size-28);
}

.xxl {
  height: var(--measure-dimension-core-size-32);
  width: var(--measure-dimension-core-size-32);
  min-height: var(--measure-dimension-core-size-32);
  min-width: var(--measure-dimension-core-size-32);
}

// Loader styles
.loader {
  color: var(--ds-comp-circular-indicator-color-fg);
  animation: rotate 2s linear infinite;

  & circle {
    stroke: currentcolor;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

// Animation frames for the loader
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
