.slider {
  @apply border border-solid border-color-border-lighter cursor-pointer w-[34px] bg-color-bg-neutral-lightest-default p-[3px] rounded-medium flex content-center flex-shrink-0 shadow-sm-2 shadow-base-colors-grey-400/25 ease-in-out duration-[300ms];

  & span {
    @apply block aspect-square w-[-13px] h-[14px] bg-color-bg-neutral-light-hovered rounded-xxx-large ease-in duration-[300ms];
  }

  &:hover {
    @apply bg-color-bg-neutral-lighter-default border-color-border-light;
  }

  &:active {
    @apply bg-color-bg-neutral-light-default border-color-bg-neutral-light-default;

    & span {
      @apply bg-color-bg-neutral-light-pressed;
    }
  }

  &.disabled {
    @apply bg-color-bg-neutral-light-default cursor-not-allowed border-color-border-light opacity-40;

    & span {
      @apply bg-color-bg-neutral-light-pressed;
    }
  }
}

.active {
  @apply bg-color-bg-brand-bold-default border border-solid border-color-border-brand-default;

  & span {
    @apply bg-color-bg-base-default ease-in duration-[300ms];

    transform: translateX(12px);
  }

  [dir='rtl'] & span {
    transform: translateX(-12px);
  }

  &:hover {
    @apply bg-color-bg-brand-bold-hovered border-color-bg-brand-bold-hovered;
  }

  &:active {
    @apply bg-color-bg-brand-bolder-pressed border-color-bg-brand-bolder-pressed;

    & span {
      @apply bg-color-bg-base-default;
    }
  }

  &.disabled {
    @apply bg-color-bg-brand-bold-default cursor-not-allowed pointer-events-auto opacity-40 border-color-bg-brand-bold-default;

    & span {
      @apply bg-color-bg-base-default;
    }
  }
}
