.tooltipTriangle::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #000 transparent transparent transparent;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
}
