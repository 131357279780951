.btnChtGpt {
  @apply px-[8px] md:px-[16px] py-[12px]  text-white flex gap-[8px] justify-center items-center;
  background-color: #2970ff;
  transition: background-color 0.3s;
}

.btnChtGpt:hover,
.btnChtGpt:focus {
  background: linear-gradient(90deg, #2970ff, transparent) #875af7;
}

.useCaseBG {
  background: linear-gradient(101.61deg, #f5f8ff 1.28%, #fbfaff 98.99%);
}

.cardBorderWrap {
  padding: 1px;
  position: relative;
  background: linear-gradient(90deg, #2970ff, transparent) #875af7;
  border-radius: 4px;
}
