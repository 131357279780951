.red {
  background: #f73c83;
}
.low-green {
  background: #a0ee45;
}
.medium-green {
  background: #00d887;
}
.high-green {
  background: #00b89e;
}
.platinum {
  background: #7c7e96;
}

.roundedFirst {
  border-radius: 4px 0 0 4px !important;
}
.roundedLast {
  border-radius: 0 4px 4px 0;
}
[lang='ar'] .roundedFirst {
  border-radius: 0 4px 4px 0 !important;
}

[lang='ar'] .roundedLast {
  border-radius: 4px 0 0 4px !important;
}
