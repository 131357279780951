/* AutoScrollCarousel.module.css */
.carouselWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.carouselTrack {
  display: flex;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  width: max-content;
}

.carouselItem {
  display: flex;
  flex-shrink: 0;
  padding: 1rem;
  gap: 2rem;
  align-items: center;
}

/* LTR Animations */
@keyframes scroll-forward {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% / 3));
  }
}

@keyframes scroll-reverse {
  0% {
    transform: translateX(calc(-100% / 3));
  }

  100% {
    transform: translateX(0);
  }
}

/* RTL Animations */
@keyframes scroll-forward-rtl {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(100% / 3));
  }
}

@keyframes scroll-reverse-rtl {
  0% {
    transform: translateX(calc(100% / 3));
  }

  100% {
    transform: translateX(0);
  }
}

/* Animation classes */
.carouselTrack.forward:not(.rtl) {
  animation-name: scroll-forward;
}

.carouselTrack.reverse:not(.rtl) {
  animation-name: scroll-reverse;
}

.carouselTrack.forward.rtl {
  animation-name: scroll-forward-rtl;
}

.carouselTrack.reverse.rtl {
  animation-name: scroll-reverse-rtl;
}

.carouselTrack.rtl {
  direction: rtl;
}
